import { Constants } from "shared/helpers/Constants";
import { languages } from "shared/helpers/languages";

export const getLang = (cc) => {
  let lang = "";
  switch (cc) {
    case "br":
      lang = "pt_BR";
      break;
    case "sr":
      lang = "sr_BA";
      break;
    case "be":
      lang = "nl_be";
      break;
    case "za":
      lang = "af";
      break;
    case "cl":
      lang = "es";
      break;
    case "co":
      lang = "es";
      break;
    case "mx":
      lang = "es";
      break;
    case "ar":
      lang = "es";
      break;
    case "au":
      lang = "en";
      break;
    case "nz":
      lang = "en";
      break;
    case "in":
      lang = "en";
      break;
    case "ie":
      lang = "en";
      break;
    case "ke":
      lang = "en";
      break;
    case "en-gb":
      lang = "en-gb";
      break;
    default:
      lang = cc;
      break;
  }
  return lang;
};

export const getCountryName = (cc) => {
  let country = Constants.countries;

  switch (cc) {
    case "br":
      country = Constants.brazil;
      break;
    case "ie":
      country = Constants.ireland;
      break;
    case "ke":
      country = Constants.kenya;
      break;
    case "en-gb":
      country = Constants.UnitedKingdom;
      break;
    case "cl":
      country = Constants.chile;
      break;
    case "co":
      country = Constants.colombia;
      break;
    case "mx":
      country = Constants.mexico;
      break;
    case "ar":
      country = Constants.argentina;
      break;
    case "au":
      country = Constants.australia;
      break;
    case "nz":
      country = Constants.newzealand;
      break;
    case "in":
      country = Constants.india;
      break;
    default:
      break;
  }
  return country;
};

export const getText = (attr, countryCode, selectedLang, form) => {
  if (selectedLang) {
    return languages[countryCode][selectedLang][form][attr];
  }
  return languages[countryCode]["en"][form][attr];
};

export const getPrivacyFormURL = (cc, relationType, lang, reqType, form) => {
  let privacyFormURL =
    cc === "za"
      ? process.env[
          `REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_MYSELF_CUSTOMER_CP_${cc}`
        ]
      : process.env[
          `REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_${reqType}_CUSTOMER_${cc}`
        ];
  if (relationType) {
    switch (relationType) {
      case getText("customer", cc, lang, form):
        privacyFormURL =
          process.env[
            `REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_${reqType}_CUSTOMER_${cc}`
          ];
        break;
      case getText("cropProtection", cc, lang, form):
        privacyFormURL =
          process.env[
            `REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_MYSELF_CUSTOMER_CP_${cc}`
          ];
        break;
      case getText("pannar", cc, lang, form):
        privacyFormURL =
          process.env[
            `REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_MYSELF_CUSTOMER_PAN_${cc}`
          ];
        break;
      case getText("pioneer", cc, lang, form):
        privacyFormURL =
          process.env[
            `REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_MYSELF_CUSTOMER_PIO_${cc}`
          ];
        break;
      case getText("vendor", cc, lang, form):
        privacyFormURL =
          process.env[
            `REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_${reqType}_VENDOR_${cc}`
          ];
        break;
      case getText("employee", cc, lang, form):
        privacyFormURL =
          process.env[
            `REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_${reqType}_EMPLOYEE_${cc}`
          ];
        break;
      default:
        break;
    }
  }
  return privacyFormURL;
};

export const getRequestTypeDes = (cc, requestType, lang, form) => {
  switch (requestType) {
    case "optOut":
      return {
        value: "optOut",
        description: getText("optOutDescription", cc, lang, form),
      };
    case "correction":
      return {
        value: "correction",
        description: getText("correctionDescription", cc, lang, form),
      };
    case "deletion":
      return {
        value: "deletion",
        description: getText("deletionDescription", cc, lang, form),
      };
    case "access":
      return {
        value: "access",
        description: getText("accessDescription", cc, lang, form),
      };
    case "withdraw-consent":
      return {
        value: "withdraw-consent",
        description: getText("withdrawConsentDescription", cc, lang, form),
      };
    case "other":
      return {
        value: "other",
        description: getText("otherDescription", cc, lang, form),
      };
    case "delete-my-corteva-account":
      return {
        value: "delete-my-corteva-account",
        description: getText(
          "deleteMyCortevaAccountDescription",
          cc,
          lang,
          form
        ),
      };
    case "restriction-to-processing":
      return {
        value: "restriction-to-processing",
        description: getText("restrictProcessingDescription", cc, lang, form),
      };
    case "appeal":
      return {
        value: "appeal",
        description: getText("appealDescription", cc, lang, form),
      };
    default:
      return console.warn("Unknown Request Type", requestType);
  }
};

export const checkLang = (cc, lang) => {
  let bool = false;
  if (!lang) {
    return bool;
  }
  if (lang === "en") return !bool;
  Object.keys(Constants.countryLangCodes).forEach((value) => {
    if (
      value === cc &&
      Constants.countryLangCodes[value].constructor.name === "Object" &&
      Object.keys(Constants.countryLangCodes[value]).includes(lang)
    )
      bool = true;
    if (value === cc && value === lang) {
      bool = true;
    }
  });
  return bool;
};

export const checkCountryCode = (cc) => {
  const bool = false;
  if (!cc) return bool;
  if (Object.keys(Constants.countryLangCodes).includes(cc)) return !bool;
  return bool;
};
